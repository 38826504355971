import { useState } from 'react'

import { useCounter } from 'react-use'

import { ACADEMIC_YEAR } from 'env'

import Icon from '../Icon'
import InfoStep from './InfoStep'
import GeneralStep from './GeneralStep'
import UploadStep from './UploadStep'
import ConfirmStep from './ConfirmStep'
import DoneStep from './DoneStep'

import './Register.css'

import { Typography, Divider, Steps, Row, Col, message, Modal } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'

import vinciLogo from 'assets/logo_conseil_social.png'
import axios from 'axios'

const { Title } = Typography
const { Step } = Steps

const API_URL = process.env.REACT_APP_API_URL




/**
 * Steps list
 */
const steps = [
  { id: 'infos', title: 'Informations', icon: 'info-circle' },
  { id: 'general', title: 'Coordonnées', icon: 'address-card' },
  { id: 'upload', title: 'Documents', icon: 'upload' },
  { id: 'confirm', title: 'Confirmation', icon: 'check-double' },
  { id: 'done', title: 'C\'est terminé !', icon: 'smile' }
]


export interface FormValues {
  file?: UploadFile
  lastname?: string
  firstname?: string
  email?: string
  number?: string
  iban?: string
}


/**
 * Main view
 */
const Register = () => {

  const [current, { inc: next, dec: prev }] = useCounter(0)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<FormValues>({})

  const updateValues = (formValues: object) => {
    setValues(Object.assign({}, formValues))
    next()
  }

  const setFile = (formValues: any) => {
    setValues({ ...values, file: Object.assign({}, formValues.file[0]) })
    next()
  }

  const register = () => {
    setLoading(true)
    const formData = new FormData();
    (Object.keys(values) as Array<keyof FormValues>).forEach(key => {
      if (key === 'file') formData.append('file', values.file!.originFileObj!)
      else formData.append(key, values[key]!)
    })
    axios.post(API_URL + 'requests', formData)
      .then((data) => {
        setValues(data as FormValues)
        setLoading(false)
        next()
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (error.response.message) {
            message.error(error.message)
          } else {
            Modal.warning({
              title: "Un champ du formulaire n'est pas correct.",
              content: <>
                <p>Il semblerait qu'un champ contient des données incorrectes. Vérifiez que les champs ne contiennent pas de caractères spéciaux, et qu'ils sont bien tous complétés."</p>
                <p>Si le problème persiste malgré que vous ayez tout vérifié, transmettez nous les informations suivantes par mail :</p>
                <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </>
            })
          }
        } else if (error.response.status === 409) {
          Modal.warning({
            title: "Impossible d'enregistrer la demande !",
            content: <>
              <p>Une demande de remboursement est déjà enregistrée à votre nom.</p>
              <p>Nous vous rappelons que tout étudiant ne peut soumettre qu'une seule et unique demande de remboursement.</p>
              <p>Cette seconde demande ne peut donc pas être prise en compte.</p>
            </>
          })
        } else if (error.response.status === 429) {
          Modal.warning({
            title: "Vous avez essayé trop de fois !",
            content: <>
              <p>Vous avez essayé un peu trop souvent. Attendez quelques minutes et ré-essayez de soumettre le formulaire</p>
            </>
          })
        } else {
          Modal.error({
            title: "Une erreur inconnue est survenue",
            content: <>
              <p>Merci de ré-essayer. Si le problème persiste, transmettez nous les informations suivantes par mail pour tenter de résoudre le problème :</p>
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </>
          })
        }
        setLoading(false)
      })
  }

  return (
    <div className="register-form-container">
      <div className="register-form-scroll">
        <div className="register-form">
          <Row>
            <Col span={17}>
              <Title level={3}>Demande de remboursement supports de cours pour les étudiants boursiers<br /><small style={{ color: "grey" }}>Année académique {ACADEMIC_YEAR}</small></Title>
            </Col>
            <Col span={7}>
              <img src={vinciLogo} alt="logo vinci" style={{ maxWidth: "100%", paddingLeft: "20px" }} />
            </Col>
          </Row>

          <Divider />

          <Steps current={current} size="small" labelPlacement="vertical">
            {steps.map(item => (
              <Step key={item.id} title={item.title} icon={<Icon icon={item.icon} />} />
            ))}
          </Steps>

          <Divider />

          <div className="steps-content">
            <div hidden={current !== 0}><InfoStep next={() => next()} /></div>
            <div hidden={current !== 1}><GeneralStep prev={() => prev()} next={values => updateValues(values)} /></div>
            <div hidden={current !== 2}><UploadStep prev={() => prev()} next={(files) => setFile(files)} /></div>
            <div hidden={current !== 3}><ConfirmStep prev={() => prev()} confirm={() => register()} loading={loading} values={values} /></div>
            <div hidden={current !== 4}><DoneStep /></div>
          </div>

          <Divider />

          <Row className="register-form-footer">
            <Col span={4} offset={10}>
              <img src={vinciLogo} alt="logo vinci" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )

}

export default Register
import Icon from "components/Icon";

import { Button, Input, Space } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";



export const getColumnSearchProps = (dataIndex: string) => ({

  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Rechercher...`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button type="primary" onClick={() => confirm()} icon={<Icon icon="search" />} size="small">Rechercher</Button>
        <Button onClick={clearFilters} size="small">Annuler</Button>
        <Button type="link" size="small" onClick={() => confirm({ closeDropdown: false })}>Filtrer</Button>
      </Space>
    </div>
  ),

  filterIcon: (filtered: any) => <Icon icon="search" style={{ color: filtered ? '#1890ff' : undefined }} />,

  onFilter: (value: any, record: any) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''

});
import { FormValues } from './Register'
import Icon from 'components/Icon'

import { Typography, Divider, Form, Button, Descriptions, Alert, Col, Row } from 'antd'

const { Title } = Typography



interface ConfirmStepProps {
  confirm: () => void
  prev: () => void
  loading: boolean
  values: FormValues
}



const ConfirmStep = ({ prev, confirm, loading, values }: ConfirmStepProps) => {
  return (
    <Form name="confirm" onFinish={confirm}>
      <Title level={4}>Voici les données que vous allez soumettre</Title>
      <Descriptions column={1} size="middle" bordered style={{ marginTop: "24px", marginBottom: "24px" }}>
        <Descriptions.Item label="Nom">{values.lastname}</Descriptions.Item>
        <Descriptions.Item label="Prénom">{values.firstname}</Descriptions.Item>
        <Descriptions.Item label="Adresse e-mail">{values.email}</Descriptions.Item>
        <Descriptions.Item label="Matricule Vinci">{values.number}</Descriptions.Item>
        <Descriptions.Item label="Compte bancaire">{values.iban}</Descriptions.Item>
        <Descriptions.Item label="Scan de la carte de banque">{values.file && values.file.name}</Descriptions.Item>
      </Descriptions>
      <Alert message="Attention" description="Nous vous rappelons que tout étudiant ne peut soumettre qu'une seule et unique demande de remboursement." type="warning" showIcon />
      <Divider dashed />
      <Row justify="space-between">
        <Col><Button size="large" icon={<Icon icon="arrow-left" />} disabled={false} onClick={() => prev()}>Précédent</Button></Col>
        <Col><Button type="primary" size="large" icon={<Icon icon="check" />} htmlType="submit" loading={loading}>Envoyer ma demande</Button></Col>
      </Row>
    </Form>
  )
}

export default ConfirmStep
import Icon from './Icon'

import { Tag } from 'antd'



export enum RequestStatusEnum {
  WAITING = "WAITING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  EXPORTED = "EXPORTED"
}

interface RequestStatusProps {
  status: RequestStatusEnum
}

const RequestStatus = ({ status }: RequestStatusProps) => {
  switch (status) {
    case RequestStatusEnum.WAITING: return <Tag color="blue"><Icon icon="hourglass" /> En attente</Tag>
    case RequestStatusEnum.ACCEPTED: return <Tag color="gold"><Icon icon="check" /> Acceptée</Tag>
    case RequestStatusEnum.REJECTED: return <Tag color="volcano"><Icon icon="times" /> Refusée</Tag>
    case RequestStatusEnum.EXPORTED: return <Tag color="green"><Icon icon="file-export" /> Exportée</Tag>
    default: return null
  }
}
export default RequestStatus
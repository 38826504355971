import { useContext, useEffect, useState } from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import { clone } from 'lodash'

import Icon from '../Icon'
import { AuthContext } from 'contexts/AuthContext'
import RequestStatus, { RequestStatusEnum } from 'components/RequestStatus'
import { dateSorter, getTextSorter, requestStatusFilters, requestStatusOnFilter, download } from 'utils/utils'
import { getColumnSearchProps } from 'utils/searchUtils'

import './AllRequests.scss'

import { message, PageHeader, Table, Button, Popconfirm, Modal, Form, Checkbox, Space, Input, Descriptions } from 'antd'

const { Column } = Table



const API_URL = process.env.REACT_APP_API_URL

const reasons = [
  "Vous n’êtes pas boursier.",
  "Le scan du recto de votre carte de banque n’a pas été mis en pièce jointe.",
  "La copie de votre carte de banque est illisible ; veuillez introduire une nouvelle demande avec un extrait de compte dont vous aurez noirci les éléments confidentiels. Joignez ce fichier dans la zone réservée à la carte bancaire.",
  "Impossible d’ouvrir le scan de votre carte bancaire ; veuillez introduire une nouvelle demande avec un extrait de compte dont vous aurez noirci les éléments confidentiels. Joignez ce fichier dans la zone réservée à la carte bancaire.",
  "Le numéro de la carte bancaire ne correspond pas au numéro de compte bancaire que vous avez indiqué sur votre demande.",
  "Les cartes de crédit ne sont pas recevables ; le remboursement ne peut être effectué que sur un compte bancaire.",
  "Votre demande de bourse n'a pas encore été acceptée par le Service Allocation des Etudes. Veuillez réintroduire une nouvelle demande de remboursement du forfait supports de cours quand le oui définitif vous aura été communiqué."
]



const AllRequests = ({ status }: { status?: RequestStatusEnum }) => {

  const { token } = useContext(AuthContext)

  const [form] = Form.useForm()
  const [editForm] = Form.useForm()

  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [currentRequest, setCurrentRequest] = useState<any>(null)
  const [selectedRequests, setSelectedRequests] = useState<number[]>([])

  const loadRequests = () => {
    setLoading(true)
    let url = API_URL + '/requests'
    if (status === RequestStatusEnum.WAITING) url += '?type=to-validate'
    if (status === RequestStatusEnum.ACCEPTED) url += '?type=to-export'
    axios.get(url, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(({ data }) => {
        setRequests(data)
        setLoading(false)
      })
      .catch(e => {
        message.error("Impossible de charger les demandes. Si le problème persiste, contactez l'administrateur.")
        setLoading(false)
      })
  }

  useEffect(loadRequests, [token, status])

  const downloadFile = async (file: string) => {
    const response = await axios.get(API_URL + '/uploads/' + file, { headers: { 'Authorization': 'Bearer ' + token }, responseType: 'blob' })
    download(file, response.data)
  }

  const acceptRequest = (id: number) => {
    axios.post(`${API_URL}/requests/${id}/accept`, null, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(() => {
        loadRequests()
        closeRejectModal()
        message.success("Demande acceptée")
      })
      .catch(() => message.error("Impossible d'accepter la demande. Si le problème persiste, contactez l'administrateur."))
  }

  const resetRequest = (id: number) => {
    axios.post(`${API_URL}/requests/${id}/reset`, null, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(() => {
        loadRequests()
        closeRejectModal()
        message.success("Demande modifiée")
      })
      .catch(() => message.error("Impossible de modifier la demande. Si le problème persiste, contactez l'administrateur."))
  }

  const deleteRequest = (id: number) => {
    axios.delete(`${API_URL}/requests/${id}`, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(() => {
        loadRequests()
        message.success("Demande supprimée")
      })
      .catch(() => message.error("Impossible de supprimer la demande. Si le problème persiste, contactez l'administrateur."))
  }

  const openRejectModal = (request: object) => {
    setCurrentRequest(request)
    setIsModalVisible(true)
  }

  const openEditModal = (request: object) => {
    setCurrentRequest(request)
    editForm.setFieldsValue(request)
    setIsEditModalVisible(true)
  }

  const closeRejectModal = () => {
    setCurrentRequest(null)
    setIsModalVisible(false)
    form.resetFields()
  }

  const closeEditModal = () => {
    setCurrentRequest(null)
    setIsEditModalVisible(false)
    editForm.resetFields()
  }

  const editRequest = (values: any) => {
    axios.put(`${API_URL}/requests/${currentRequest.id}`, values, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(() => {
        loadRequests()
        closeEditModal()
        message.success("Demande modifiée")
        closeEditModal()

      })
      .catch(() => message.error("Impossible de modifier la demande. Si le problème persiste, contactez l'administrateur."))
  }

  const rejectRequest = () => {
    const values = form.getFieldsValue()
    const reasons = clone(values.reasons) || []
    if (values.other) reasons.push(values.other)
    if (!reasons.length) return message.warning("Merci de donner au minimum une raison.")
    axios.post(`${API_URL}/requests/${currentRequest.id}/reject`, { reasons }, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(() => {
        loadRequests()
        closeRejectModal()
        message.success("Demande refusée")
      })
      .catch(() => message.error("Impossible de refuser la demande. Si le problème persiste, contactez l'administrateur."))
  }

  const exportRequests = () => {
    axios.post(`${API_URL}/requests/export`, { requests: selectedRequests }, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(response => {
        download(response.headers['content-disposition']?.match(/filename="(.*)"/i)![1], response.data)
        loadRequests()
        message.success("Demandes exportées")
      })
      .catch(() => message.error("Impossible d'exporter les demandes. Si le problème persiste, contactez l'administrateur."))
  }

  const downloadRequests = () => {
    axios.post(`${API_URL}/requests/download`, null, { headers: { 'Authorization': 'Bearer ' + token } })
      .then(response => {
        download(response.headers['content-disposition']?.match(/filename="(.*)"/i)![1], response.data)
        loadRequests()
        message.success("Demandes téléchargées")
      })
      .catch(() => message.error("Impossible de télécharger les demandes. Si le problème persiste, contactez l'administrateur."))
  }

  return <>
    {status === RequestStatusEnum.WAITING && <PageHeader title={<><Icon icon="check" /> Demandes à valider</>} style={{ marginBottom: 15, paddingLeft: 5 }} />}
    {status === RequestStatusEnum.ACCEPTED && <PageHeader
      title={<><Icon icon="file-export" /> Demandes à exporter</>}
      style={{ marginBottom: 15, paddingLeft: 5 }}
      extra={<Button icon={<Icon icon="file-excel" />} disabled={!selectedRequests.length} onClick={exportRequests}>Exporter ({selectedRequests.length})</Button>}
    />}
    {!status && <PageHeader
      title={<><Icon icon="list-ul" /> Toutes les demandes</>}
      style={{ marginBottom: 15, paddingLeft: 5 }}
      extra={<Button icon={<Icon icon="file-excel" />} onClick={downloadRequests}>Télécharger au format Excel</Button>}
    />}
    <Table
      size='small'
      dataSource={requests}
      rowKey="id"
      rowClassName={r => r.status !== RequestStatusEnum.WAITING ? "row-clickable" : ""}
      loading={loading}
      pagination={{ defaultPageSize: 20 }}
      expandable={{
        expandRowByClick: true,
        expandIconColumnIndex: -1,
        rowExpandable: r => r.status !== RequestStatusEnum.WAITING,
        expandedRowRender: (r: any) => <>
          <Descriptions bordered size="small" column={1} className="request-descriptions">
            {(r.status === RequestStatusEnum.ACCEPTED || r.status === RequestStatusEnum.EXPORTED) && <Descriptions.Item label={<><Icon icon="check" fixedWidth /> Acceptée le</>}>{dayjs(r.validationDate).format('L à LT')}</Descriptions.Item>}
            {r.status === RequestStatusEnum.REJECTED && <Descriptions.Item label={<><Icon icon="times" fixedWidth /> Refusée le</>}>{dayjs(r.validationDate).format('L à LT')}</Descriptions.Item>}
            {r.status === RequestStatusEnum.REJECTED && <Descriptions.Item label={<><Icon icon="question-circle" fixedWidth /> Raison de refus</>}><ul>{r.rejectedReasons.map((r: string) => <li key={r}>{r}</li>)}</ul></Descriptions.Item>}
            {r.status === RequestStatusEnum.EXPORTED && <Descriptions.Item label={<><Icon icon="file-export" fixedWidth /> Exportée le</>}>{dayjs(r.exportDate).format('L à LT')}</Descriptions.Item>}
          </Descriptions>
        </>
      }}
      rowSelection={status === RequestStatusEnum.ACCEPTED ? {
        type: 'checkbox',
        onChange: (selectedRowKeys) => setSelectedRequests(selectedRowKeys.map(k => Number(k)))
      } : undefined}
    >
      <Column title="Date" dataIndex="date" render={d => dayjs(d).format('L LT')} sorter={dateSorter} />
      <Column title="Nom" dataIndex="lastname" sorter={getTextSorter('lastname')} {...getColumnSearchProps('lastname')} />
      <Column title="Prénom" dataIndex="firstname" sorter={getTextSorter('firstname')} {...getColumnSearchProps('firstname')} />
      <Column title="Email" dataIndex="email" sorter={getTextSorter('email')} {...getColumnSearchProps('email')} />
      <Column title="Matricule" dataIndex="number" sorter={getTextSorter('number')}  {...getColumnSearchProps('number')} />
      <Column title="IBAN" dataIndex="iban" sorter={getTextSorter('iban')}  {...getColumnSearchProps('iban')} />
      <Column title="Etat" dataIndex="status" render={s => <RequestStatus status={s} />} filters={requestStatusFilters} onFilter={requestStatusOnFilter} />
      <Column title="Actions" render={request => <>
        <Button shape='circle' size="small" icon={<Icon icon="credit-card" />} onClick={() => downloadFile(request.file)} />
        {request.status === RequestStatusEnum.WAITING && <Button shape='circle' size="small" icon={<Icon icon="pencil" />} onClick={() => openEditModal(request)} style={{ marginLeft: 10 }} />}
        {request.status === RequestStatusEnum.WAITING && <Popconfirm title={"Êtes-vous sûr ?"} onConfirm={() => acceptRequest(request.id)} okText="Oui" cancelText="Non">
          <Button
            shape='circle'
            size="small"
            icon={<Icon icon="check" />}
            style={{ marginLeft: 10, color: '#52c41a', borderColor: '#52c41a' }}
          />
        </Popconfirm>}
        {request.status === RequestStatusEnum.WAITING && <Button
          shape='circle'
          size="small"
          icon={<Icon icon="times" />}
          danger
          onClick={() => openRejectModal(request)}
          style={{ marginLeft: 10 }}
        />}
        {request.status === RequestStatusEnum.WAITING && <Popconfirm title="Êtes-vous sûr ?" onConfirm={() => deleteRequest(request.id)}        >
          <Button shape='circle' size="small" icon={<Icon icon="trash" />} danger style={{ marginLeft: 10 }} />
        </Popconfirm>}
        {request.status === RequestStatusEnum.ACCEPTED && <Popconfirm title="Êtes-vous sûr ?" onConfirm={() => resetRequest(request.id)}        >
          <Button shape='circle' size="small" icon={<Icon icon="undo" />} danger style={{ marginLeft: 10 }} />
        </Popconfirm>}
      </>} />
    </Table>
    <Modal title="Choisissez une ou plusieurs raisons" visible={isModalVisible} onOk={rejectRequest} onCancel={closeRejectModal}>
      <Form form={form} layout="vertical">
        <Form.Item name="reasons" noStyle>
          <Checkbox.Group>
            <Space direction="vertical" size="middle">
              {reasons.map(r => <Checkbox key={r} value={r}>{r}</Checkbox>)}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name="other" noStyle>
          <Input.TextArea placeholder='Autre raison' style={{ marginTop: 25 }} autoSize />
        </Form.Item>
      </Form>
    </Modal>
    <Modal title={`Modifier la demande : ${currentRequest?.firstname} ${currentRequest?.lastname}`} visible={isEditModalVisible} onOk={editForm.submit} onCancel={closeEditModal}>
      <Form form={editForm} onFinish={editRequest} layout="vertical">
        <Form.Item name="number" label="Matricule">
          <Input />
        </Form.Item>
        <Form.Item name="iban" label="Iban">
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  </>

}
export default AllRequests
import { useContext } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { AuthContext } from 'contexts/AuthContext'
import Loader from 'components/Loader'



const RequireAuth = ({ children }: { children: JSX.Element }) => {

  const { isAuthenticated, isLoading } = useContext(AuthContext)
  const location = useLocation()

  if (isLoading) return <Loader />
  if (isAuthenticated) return children
  return <Navigate to="/login" state={{ from: location }} replace />

}

export default RequireAuth
import React, { FC, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'



const API_URL = process.env.REACT_APP_API_URL



type Credentials = {
  name: string,
  password: string,
  remember: boolean
}

interface IAuthContext {
  isAuthenticated: boolean
  user: Object | null
  token: string | null
  isLoading: boolean
  isRecovering: boolean
  login: (credentials: Credentials) => Promise<AxiosResponse>
  logout: () => void
}



const defaultState = {
  isAuthenticated: false,
  user: null,
  token: null,
  isLoading: true,
  isRecovering: true,
}

const AuthContext = React.createContext<IAuthContext>(null!)



interface AuthProviderProps {
  children: React.ReactNode
}


const AuthProvider: FC<AuthProviderProps> = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(defaultState.isAuthenticated)
  const [user, setUser] = useState(defaultState.user)
  const [token, setToken] = useState(defaultState.token)
  const [isLoading, setIsLoading] = useState(defaultState.isLoading)
  const [isRecovering, setIsRecovering] = useState(defaultState.isRecovering)

  const login = (credentials: Credentials): Promise<AxiosResponse> => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      axios.post(API_URL + '/auth/login', credentials)
        .then(response => {
          if (credentials.remember) {
            localStorage.setItem('token', response.data.token)
          } else {
            sessionStorage.setItem('token', response.data.token)
          }
          setToken(response.data.token)
          setUser(response.data.user)
          setIsAuthenticated(true)
          setIsLoading(false)
          resolve(response.data.user)
        })
        .catch(error => {
          setIsLoading(false)
          reject(error.response)
        })
    })
  }

  const logout = () => {
    setIsAuthenticated(false)
    setToken(null)
    setUser(null)
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }

  const recover = () => {
    // Check if token exists in localstorage
    let token = localStorage.getItem('token')
    // Check if token exists in sessionStorage
    if (!token) {
      token = sessionStorage.getItem('token')
    }
    // If no token, stop recovering
    if (!token) {
      setIsLoading(false)
      setIsRecovering(false)
      return
    }
    axios.get(API_URL + '/auth/user', { headers: { 'Authorization': 'Bearer ' + token } })
      .then(response => {
        setToken(token as any)
        setIsAuthenticated(true)
        setIsLoading(false)
        setIsRecovering(false)
        setUser(response.data.user)
      })
      .catch(response => {
        setIsLoading(false)
        setIsRecovering(false)
        logout()
      })
  }

  useEffect(recover, [])

  const exposedValue: IAuthContext = {
    isAuthenticated,
    user,
    token,
    isLoading,
    isRecovering,
    login,
    logout
  }

  return <AuthContext.Provider value={exposedValue}>
    {children}
  </AuthContext.Provider>

}

export { AuthContext, AuthProvider }
export default AuthContext
import Router from './Router'

import { ConfigProvider } from 'antd'
import frBE from 'antd/es/locale/fr_BE';

// Init fontawesome
import { IconPack, library } from '@fortawesome/fontawesome-svg-core'
import { fal } from 'fontawesome-pro-light-svg-icons'
library.add(fal as IconPack)



const App = () => (
  <ConfigProvider locale={frBE}>
    <Router />
  </ConfigProvider>
)

export default App
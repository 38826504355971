
import './Closed.css'

import { CLOSED_ACADEMIC_YEAR } from 'env'

import vinciLogo from 'assets/logo_conseil_social.png'



const Closed = () => <div className='closed-content'>
  <img src={vinciLogo} alt="logo vinci" style={{ maxWidth: 340 }} />
  <h1>Le formulaire est clôturé.</h1>
  <h2>Le formulaire de demande de remboursement supports de cours est clôturé pour l'année académique {CLOSED_ACADEMIC_YEAR}.
  </h2>
  <h3>Formulaire de demande de remboursement supports de cours - Année académique {CLOSED_ACADEMIC_YEAR}</h3>
</div>

export default Closed
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Closed from './Closed/Closed'
import Register from './Register/Register'
import RequireAuth from './RequireAuth'
import Admin from './Admin/Admin'
import { AuthProvider } from 'contexts/AuthContext'

import { FORM_CLOSED } from 'env'
import Login from './Login/Login'



const Router = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={FORM_CLOSED ? <Closed /> : <Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/*" element={<RequireAuth><Admin /></RequireAuth>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
)

export default Router
import { Result } from 'antd'



const DoneStep = () => {
  return (
    <Result
      style={{ marginBottom: "60px" }}
      status="success"
      title="Nous avons bien reçu votre demande !"
      subTitle={<>Nous traitons votre demande de remboursement supports de cours dans les meilleurs délais.<br />Vous serez averti par mail du suivi de votre demande. Le délai de paiement peut être plus ou moins long en fonction du nombre de demande à traiter. Merci de votre compréhension.</>}
    />
  )
}

export default DoneStep
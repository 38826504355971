import Icon from 'components/Icon'

import { ACADEMIC_YEAR, CLOSE_DATE } from 'env'

import { Alert, Button, Col, Divider, Row, Typography } from 'antd'

const { Paragraph } = Typography



interface InfoStepProps {
  next: () => void
}



const InfoStep = ({ next }: InfoStepProps) => {
  return (
    <>
      <Paragraph>Durant cette année académique {ACADEMIC_YEAR}, suite à une décision du Conseil social, <strong>chaque étudiant boursier</strong> peut bénéficier d’un forfait de remboursement supports de cours de 100,00 € et ceci, quelles que soient sa section et son année d’études.</Paragraph>
      <Paragraph strong>Pour cela, veuillez remplir ce formulaire, en joignant un scan du coté de votre carte de banque sur lequel apparait votre numéro de compte bancaire ; celui où vous désirez que le versement soit effectué.</Paragraph>
      <Alert type="warning" message={<>Ce formulaire de demande peut être complété <strong>jusqu'au {CLOSE_DATE}</strong>.</>} showIcon style={{ marginBottom: 20 }} />
      <Paragraph strong underline>Si votre demande est complète</Paragraph>
      <Paragraph>Dans les 10 jours de l’envoi de votre demande, vous recevrez un mail vous informant que celle-ci a été traitée et que le paiement a été exécuté vers le compte bancaire indiqué. Le remboursement sera effectué par le service Comptabilité du service social de la Haute Ecole sur le compte bancaire indiqué. Le délai de paiement peut être plus ou moins long en fonction du nombre de demande à traiter. Merci de votre compréhension.</Paragraph>
      <Paragraph strong underline>Si votre demande est incomplète</Paragraph>
      <Paragraph>Vous recevrez un mail vous indiquant pourquoi votre demande n’a pas pu être traitée et vous demandant, si vous êtes bien boursier, de réintroduire une nouvelle demande sur base des éléments demandés. Dans les 10 jours de la réception de cette nouvelle demande, vous serez avertis par mail de son traitement et du paiement du forfait supports de cours sur votre compte bancaire.</Paragraph>
      <Paragraph strong>Le forfait de 100,00 € n’est pas suffisant pour faire face à vos dépenses de supports de cours ?</Paragraph>
      <Paragraph>Vous devez alors contacter votre intervenante sociale via l’adresse mail <a href="mailto:service.social@vinci.be">service.social@vinci.be</a> elle analysera vos besoins avec vous et pourra vous proposer des solutions adaptées à votre situation pour compléter ce forfait par une aide individuelle.</Paragraph>
      <Paragraph>Nous sommes à votre disposition pour tout renseignement complémentaire.</Paragraph>
      <Paragraph>Le service social</Paragraph>
      <Divider dashed />
      <Row justify="end">
        <Col><Button type="primary" size="large" icon={<Icon icon="arrow-right" />} onClick={next}>Suivant</Button></Col>
      </Row>
    </>
  )
}

export default InfoStep
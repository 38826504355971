import { RequestStatusEnum } from 'components/RequestStatus'
import dayjs from 'dayjs'



export const getTextSorter = (column: string) => (a: any, b: any) => a[column].localeCompare(b[column])

export const dateSorter = (a: string, b: string) => {
  const date1 = dayjs(a)
  const date2 = dayjs(b)
  if (date1 === date2) return 0
  return date1 > date2 ? 1 : -1
}

export const requestStatusFilters = [
  { text: 'En attente', value: RequestStatusEnum.WAITING },
  { text: 'Validée', value: RequestStatusEnum.ACCEPTED },
  { text: 'Refusée', value: RequestStatusEnum.REJECTED },
  { text: 'Exportée', value: RequestStatusEnum.EXPORTED }
]

export const requestStatusOnFilter = (value: string | number | boolean, record: any) => record.status.indexOf(value) === 0

export const download = async (filename: string, data: any) => {
  const objectUrl = URL.createObjectURL(new Blob([data]))
  const link: HTMLAnchorElement = document.createElement('a')
  link.href = objectUrl
  link.download = filename
  link.click()
  URL.revokeObjectURL(objectUrl)
}
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'


interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icon: string | string[]
}

const Icon = (props: IconProps) => {
  const { ...newProps } = props
  if (typeof props.icon === "string") {
    newProps.icon = ["fal", props.icon]
  }
  return <FontAwesomeIcon {...newProps as FontAwesomeIconProps} />
}

export default Icon
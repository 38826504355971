import { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import Icon from '../Icon'
import { AuthContext } from 'contexts/AuthContext'
import { RequestStatusEnum } from 'components/RequestStatus'
import { download } from 'utils/utils'

import './Exports.scss'

import { message, PageHeader, List } from 'antd'



const API_URL = process.env.REACT_APP_API_URL



const AllRequests = ({ status }: { status?: RequestStatusEnum }) => {

  const { token } = useContext(AuthContext)

  const [exports, setExports] = useState([])
  const [loading, setLoading] = useState(false)

  const loadExports = () => {
    setLoading(true)
    axios.get(API_URL + '/exports', { headers: { 'Authorization': 'Bearer ' + token } })
      .then(({ data }) => {
        setExports(data)
        setLoading(false)
      })
      .catch(e => {
        message.error("Impossible de charger les exports. Si le problème persiste, contactez l'administrateur.")
        setLoading(false)
      })
  }

  useEffect(loadExports, [token, status])

  const downloadFile = async (file: string) => {
    const response = await axios.get(API_URL + '/exports/' + file, { headers: { 'Authorization': 'Bearer ' + token }, responseType: 'blob' })
    download(file, response.data)
  }

  return <>
    <PageHeader title={<><Icon icon="history" /> Historique des exports</>} style={{ marginBottom: 15, paddingLeft: 5 }} />
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={exports}
      size="small"
      renderItem={exp => <List.Item onClick={() => downloadFile(exp)} style={{ cursor: 'pointer' }}>{exp}</List.Item>}
    />
  </>

}
export default AllRequests
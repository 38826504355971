import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import './index.scss'

import App from './components/App'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(LocalizedFormat)
dayjs.locale('fr')


ReactDOM.render(<App />, document.getElementById('root'))



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

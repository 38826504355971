import { useState } from 'react'

import Icon from 'components/Icon'
import { FILE_UPLOAD_MAX_SIZE } from 'env'

import { Typography, Divider, Form, Button, Upload, Alert, Row, Col } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'

const { Title } = Typography



interface UploadStepProps {
  next: (files: UploadFile[]) => void
  prev: () => void
}



const UploadStep = ({ next, prev }: UploadStepProps) => {

  const [error, setError] = useState<string>()

  const normFile = (e: any) => {
    // Get fileList
    let fileList: UploadFile[]
    if (Array.isArray(e)) {
      fileList = e
    }
    fileList = e && e.fileList;
    // Handle fileList
    if (Array.isArray(fileList)) {
      fileList = fileList.slice(-1)
    }
    // Check file size and type
    if (fileList.length > 0) {
      const file = fileList[0]
      if (!file.size || file.size > FILE_UPLOAD_MAX_SIZE * 1024 * 1024) {
        setError(`Le fichier est trop gros : ${+(file.size! / 1024 / 1024).toFixed(2)}Mo. La taille maximale est de ${FILE_UPLOAD_MAX_SIZE}Mo.`)
        fileList = []
      } else if (file.size <= 10) {
        setError("Le fichier semble vide.")
        fileList = []
      } else if (file.type !== "application/pdf" && file.type !== "image/jpeg" && file.type !== "image/png") {
        setError(`Le fichier n'est pas au format PDF/JPG/PNG : ${file.type}. Seul un fichier au format pdf est autorisé.`)
        fileList = []
      } else {
        setError(undefined)
      }
    }
    return fileList
  }

  return (
    <Form name="upload" onFinish={(values) => next(values)}>
      <Title level={4}>Uploadez un scan du côté de la carte sur lequel est indiqué votre nom et le numéro de compte bancaire</Title>
      <Alert message="La carte de banque doit être celle du compte sur lequel vous désirez que le versement soit effectué." showIcon type="warning" style={{ marginBottom: 25 }} />
      <Alert message="Les cartes de crédit ne sont pas acceptées." showIcon type="warning" style={{ marginBottom: 25 }} />
      <Form.Item name="file" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: "Veuillez uploader un fichier maximum" }]} noStyle>
        <Upload.Dragger multiple={false} name="file" accept=".pdf,.jpg,.jpeg,.png" listType="picture" beforeUpload={() => false}>
          <p className="ant-upload-drag-icon"><Icon icon="inbox" /></p>
          <p className="ant-upload-text">Cliquez ou déposez le fichier dans cette zone</p>
          <p className="ant-upload-hint">Un seul fichier peut être envoyé, au format PDF/JPG/PNG uniquement, maximum {FILE_UPLOAD_MAX_SIZE}Mo.</p>
        </Upload.Dragger>
      </Form.Item>
      {error && <Alert message="Erreur d'envoi !" description={error} type="error" showIcon closable style={{ marginTop: "8px" }} />}
      <Divider dashed />
      <Row justify="space-between">
        <Col><Button size="large" icon={<Icon icon="arrow-left" />} disabled={false} onClick={() => prev()}>Précédent</Button></Col>
        <Col><Button type="primary" size="large" icon={<Icon icon="arrow-right" />} htmlType="submit">Suivant</Button></Col>
      </Row>
    </Form>
  )

}

export default UploadStep
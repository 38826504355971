import { useContext } from 'react'

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import AuthContext from 'contexts/AuthContext'
import Icon from 'components/Icon'
import AllRequests from 'components/AllRequests/AllRequests'
import { RequestStatusEnum } from 'components/RequestStatus'
import Exports from 'components/Exports/Exports'

import './Admin.scss'

import neoxisLogo from 'assets/NEOXIS_logo.png'
import vinciLogo from 'assets/VINCI_logo_white.png'
import neoxisLogoInline from 'assets/NEOXIS_logo_inline.png'

import { Button, Layout, Menu, MenuProps, Modal } from 'antd'


const { Header, Content, Footer } = Layout

const { REACT_APP_VERSION, REACT_APP_API_URL } = process.env



const Admin = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const { logout } = useContext(AuthContext)

  const info = () => {
    Modal.info({
      icon: <Icon icon="info-circle" />,
      title: 'A propos',
      closable: true,
      maskClosable: true,
      className: "info-modal",
      content: (
        <div>
          <p>Application dévelopée par</p>
          <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={neoxisLogo} alt="Neoxis Logo" /></a>
          <p>pour le service social.</p>
          <p>
            Version : {REACT_APP_VERSION}
            <br />
            API : {REACT_APP_API_URL}
          </p>
          <p>&copy; {new Date().getFullYear()}</p>
        </div>
      )
    })
  }

  const handleLogoutClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'logout':
        logout()
        break
      default:
        console.warn('Unknown menu key: ' + e.key)
    }
  }

  const handleMenuClick: MenuProps['onClick'] = e => navigate(e.key)


  return (
    <Layout className="app-layout">
      <Header>
        <img src={vinciLogo} className="logo" alt="Logo Vinci" />
        <div className='menu'>
          <Menu theme='dark' mode="horizontal" onClick={handleMenuClick} selectedKeys={[location.pathname]}>
            <Menu.Item key="/admin/requests-to-validate" icon={<Icon icon="check" />}>Demandes à valider</Menu.Item>
            <Menu.Item key="/admin/requests-to-export" icon={<Icon icon="file-export" />}>Demandes à exporter</Menu.Item>
            <Menu.Item key="/admin/all-requests" icon={<Icon icon="list-ul" />}>Toutes les demandes</Menu.Item>
            <Menu.Item key="/admin/exports" icon={<Icon icon="history" />}>Historique des exports</Menu.Item>
          </Menu>
        </div>
        <div className="logout">
          <Menu theme='dark' mode="horizontal" onClick={handleLogoutClick} >
            <Menu.Item key="logout" icon={<Icon icon="sign-out" />}>Se déconnecter</Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content>
        <div className="main-content-wrapper">
          <Routes>
            <Route path="requests-to-validate" element={<AllRequests status={RequestStatusEnum.WAITING} />} />
            <Route path="requests-to-export" element={<AllRequests status={RequestStatusEnum.ACCEPTED} />} />
            <Route path="all-requests" element={<AllRequests />} />
            <Route path="exports" element={<Exports />} />
            <Route path="*" element={<Navigate to="requests-to-validate" />} />
          </Routes>
        </div>
        <Footer>
          &copy; {new Date().getFullYear()} - Application développée par <a href="https://www.neoxis.be" target="_blank" rel="noreferrer"><img src={neoxisLogoInline} alt="Neoxis logo" /></a>
          <Button shape="circle" icon={<Icon icon="info" />} size="small" style={{ float: 'right' }} onClick={info} />
        </Footer>
      </Content>
    </Layout>
  )
}
export default Admin
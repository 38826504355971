interface Environment {
  FILE_UPLOAD_MAX_SIZE: number
  FORM_CLOSED: number
  ACADEMIC_YEAR: string
  CLOSED_ACADEMIC_YEAR: string
  CLOSE_DATE: string
}

declare const window: {
  __env__: Environment
}

export const {
  FILE_UPLOAD_MAX_SIZE,
  FORM_CLOSED,
  ACADEMIC_YEAR,
  CLOSED_ACADEMIC_YEAR,
  CLOSE_DATE
} = window.__env__
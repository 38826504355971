import Icon from './Icon'

import { Result } from 'antd'



const Loader = () => (
  <Result
    title="Veuillez patienter"
    subTitle="Chargement en cours..."
    status="info"
    style={{ paddingTop: 100 }}
    icon={<Icon icon="spinner-third" spin color="#006f91" />}
  />
)
export default Loader
import { useContext } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import './Login.scss'

import Icon from 'components/Icon'
import AuthContext from 'contexts/AuthContext'
import Loader from 'components/Loader'

import logo_background from 'assets/VINCI_logo_background.svg'
import logo_background2 from 'assets/VINCI_FC_background_logo.svg'
import logo from 'assets/VINCI_logo_white.png'

import { Button, Form, Input, message } from 'antd'



const Login = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const { isLoading, isAuthenticated, isRecovering, login } = useContext(AuthContext)

  const from = (location.state as any)?.from?.pathname || "/admin"

  const onFinishForm = (values: { name: string; password: string; remember: boolean }) => {
    login(values)
      .then(() => { navigate(from, { replace: true }) })
      .catch((response) => {
        console.log(response.status)
        if (response.status === 401) {
          message.warning("Login ou mot de passe incorrect.")
        } else {
          message.error("Une erreur inconnue est survenue. Si le problème persiste, veuillez contacter l'administrateur.")
        }
      })
  }

  if (isAuthenticated) {
    navigate(from, { replace: true })
  }

  if (isRecovering) {
    return <Loader />
  }

  return (
    <div className="modal-layout-container">
      <div className="modal-layout-background" >
        <img src={logo_background} alt="Logo FC arrière plan" />
        <img src={logo_background2} alt="Logo FC arrière plan" />
      </div>
      <div className="modal-layout-data" style={{ maxWidth: 420 }}>
        <div className="modal-layout-header">
          <img className="logo" src={logo} alt="Logo formation continue" />
        </div>
        <div className="modal-layout-header-border">
          <div style={{ backgroundColor: "#E7276D" }} />
          <div style={{ backgroundColor: "#F8AA00" }} />
          <div style={{ backgroundColor: "#009670" }} />
        </div>
        <div className="modal-layout-content">
          <h1>Se connecter</h1>
          <Form onFinish={onFinishForm}>
            <Form.Item name="name" rules={[{ required: true, message: 'Veuillez entrer votre pseudo.' }]}>
              <Input prefix={<Icon icon="user" />} placeholder="Pseudo" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Veuillez entrer votre mot de passe.' }]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder="Mot de passe" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button loading={isLoading} type="primary" htmlType="submit" icon={<Icon icon="sign-in" />}>Se connecter</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div >
  )

}

export default Login
import { FormValues } from './Register'
import Icon from 'components/Icon'

import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd'

const { Title } = Typography



const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

interface GeneralStepProps {
  next: (values: FormValues) => void
  prev: () => void
}



const GeneralStep = ({ next, prev }: GeneralStepProps) => {
  return (
    <Form {...formItemLayout} name="general" onFinish={(values) => next(values)}>
      <Title level={4}>Qui êtes-vous ?</Title>
      <Form.Item name="lastname" label="Nom" rules={[{ required: true, message: "Veuillez entrer votre nom" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="firstname" label="Prénom" rules={[{ required: true, message: "Veuillez entrer votre prénom" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Adresse e-mail Vinci" rules={[
        { required: true, message: "Veuillez entrer votre adresse e-mail Vinci" },
        { type: 'email', message: 'Veuillez entrer une adresse e-mail valide' },
        { pattern: /^.+@student\.vinci\.be$/, message: "L'adresse e-mail doit être @student.vinci.be" }
      ]}>
        <Input />
      </Form.Item>
      <Form.Item name="number" label="Matricule étudiant" rules={[
        { required: true, message: "Veuillez entrer votre matricule étudiant" },
        { pattern: /^\d{8,9}$/, message: "Le matricule doit comporter 8 ou 9 chiffres." }
      ]}>
        <Input />
      </Form.Item>
      <Form.Item name="iban" label="Compte bancaire" rules={[
        { required: true, message: "Veuillez entrer votre compte bancaire pour le remboursement" },
        { pattern: /^[A-Za-z]{2}\d+$/, message: "Veuillez entrer un numéro IBAN valide (2 lettres suivies de chiffres, sans espaces)" }
      ]}>
        <Input />
      </Form.Item>
      <Divider dashed />
      <Row justify="space-between">
        <Col><Button size="large" icon={<Icon icon="arrow-left" />} disabled={false} onClick={() => prev()}>Précédent</Button></Col>
        <Col><Button type="primary" size="large" icon={<Icon icon="arrow-right" />} htmlType="submit">Suivant</Button></Col>
      </Row>
    </Form>
  )
}

export default GeneralStep